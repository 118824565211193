import { countWordsInFlow, OrdChatFlowDefinition } from "@xflr6/chatbot";
import { useFeatureFlag } from "configcat-react";
import React, { memo, ReactElement, useState } from "react";
import { GoNumber } from "react-icons/go";
import { MdMoreVert } from "react-icons/md";
import { useSelector } from "react-redux";

import FlatButton from "../../../components/buttons/FlatButton";
import Menu from "../../../components/menus/Menu";
import MenuItem from "../../../components/menus/MenuItem";
import Modal from "../../../components/Modal";
import Popover from "../../../components/Popover";
import useDelayedToggle from "../../../utils/useDelayedToggle";
import { selectFlowFlowSequence } from "../flowEditorSlice";
import styles from "./Editor.module.css";
import LanguageSelect from "./LanguageSelect";
import PromptsEditor from "./PromptsEditor";

export interface EditorProps {
  flowDef: OrdChatFlowDefinition;
}

function Editor(props: EditorProps): ReactElement {
  const flowSequence = useSelector(selectFlowFlowSequence);

  const {
    value: isActionMenuOpen,
    setTrueWithDelay: openActionMenuWithDelay,
    setTrueImmediate: openActionMenu,
    setFalseWithDelay: closeActionMenuWithDelay,
    setFalseImmediate: closeActionMenu,
  } = useDelayedToggle(false, {});

  const { value: isI18nEnabled } = useFeatureFlag("isI18nEnabled", false);

  const [wordCount, setWordCount] = useState<number | undefined>();

  return (
    <div className={styles.root}>
      <Modal
        title="Word Count"
        style={{
          content: {
            width: "360px",
            maxWidth: "calc(100% - var(--spacingDouble))",
            maxHeight: "calc(100% - var(--spacingDouble))",
          },
        }}
        ariaHideApp={false}
        isOpen={wordCount != null}
        onRequestClose={() => setWordCount(undefined)}
      >
        <div className={styles.wordCount}>{wordCount}</div>
        <div className={styles.wordCountMessage}>
          Please note that this number is a close estimate, not an exact count.
        </div>
        {isI18nEnabled && (
          <div className={styles.wordCountMessage}>
            Also, it represents the word count for the <b>default</b> language
            only.
          </div>
        )}
      </Modal>
      <div className={styles.topBar}>
        <div className={styles.topBarMain}>
          <LanguageSelect isReadonly={!!flowSequence} />
        </div>
        <div className={styles.topBarRight}>
          <Popover
            positions={["bottom", "top"]}
            align="end"
            reposition
            isOpen={isActionMenuOpen}
            onClickOutside={closeActionMenu}
            containerClassName={styles.actionMenu}
            content={
              <Menu
                autoClose
                closeOnEsc
                onRequestClose={closeActionMenu}
                onPointerEnter={openActionMenu}
                onPointerLeave={closeActionMenu}
              >
                <MenuItem
                  onClick={() => {
                    const wordCount = countWordsInFlow(props.flowDef);
                    setWordCount(wordCount);
                  }}
                >
                  <GoNumber />
                  &nbsp;Word Count
                </MenuItem>
              </Menu>
            }
          >
            <FlatButton
              onPointerEnter={openActionMenuWithDelay}
              onPointerLeave={closeActionMenuWithDelay}
            >
              <MdMoreVert size={20} />
            </FlatButton>
          </Popover>
        </div>
      </div>
      <div className={styles.prompts}>
        <PromptsEditor promptsArray={props.flowDef.promptsArray} />
      </div>
    </div>
  );
}

export default memo(Editor);
