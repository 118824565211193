import classNames from "classnames";
import React, { ReactElement } from "react";
import { GoDotFill } from "react-icons/go";

import styles from "./AdminInputsIcon.module.css";

export function AdminInputsIcon({
  size = 14,
  className,
  pending,
}: {
  size?: string | number;
  className?: string | null;
  pending?: boolean | null;
}): ReactElement {
  return (
    <GoDotFill
      size={size}
      className={classNames(
        {
          [styles.pending]: pending,
          [styles.complete]: !pending,
        },
        className
      )}
    />
  );
}
